<template>
  <div class="carrot-layer-bg">
    <div class="carrot-layer w-900px">
      <div>
        <p class="title">MCR 상세</p>
        <span class="btn-close" @click="pop.onClose()"
          ><i class="fas fa-times"></i
        ></span>
      </div>
      <div class="mt-10 tbl-light overhidden overscroll maxh-600px">
        <table class="tbl-light-list">
          <colgroup>
            <col width="150" />
            <col width="150" />
            <col width="150" />
            <col width="*" />
            <col width="110" />
          </colgroup>
          <thead>
            <tr>
              <th>본부</th>
              <th>팀</th>
              <th>팀원</th>
              <th>직급</th>
              <th>입사일</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ pop.info.office_name }}</td>
              <td>{{ pop.info.team_name }}</td>
              <td>{{ pop.info.ename }}({{ pop.info.kname }})</td>
              <td>{{ pop.info.position_name }}</td>
              <td>{{ pop.info.joindate }}</td>
            </tr>
          </tbody>
        </table>
        <table class="tbl-light-list mt-10">
          <colgroup>
            <col span="8" />
          </colgroup>
          <thead>
            <tr>
              <th>평가연도</th>
              <th>평가월</th>
              <th>구분</th>
              <th>업무열정</th>
              <th>업무내용</th>
              <th>업무태도</th>
              <th>동료관계</th>
              <th>작성일</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ pop.mcr.syear }}</td>
              <td>{{ pop.mcr.smonth }}</td>
              <td>{{ pop.mcr.reviewer_position }}</td>
              <td>{{ pop.mcr.avg_energetic }}</td>
              <td>{{ pop.mcr.avg_management }}</td>
              <td>{{ pop.mcr.avg_attitude }}</td>
              <td>{{ pop.mcr.avg_communion }}</td>
              <td>{{ pop.mcr.regdate }}</td>
            </tr>
          </tbody>
        </table>
        <table class="tbl-light-view mt-10">
          <colgroup>
            <col width="150" />
            <col width="150" />
            <col width="*" />
            <col width="150" />
          </colgroup>
          <tbody>
            <tr>
              <th rowspan="11">역량<br />리뷰</th>
              <th>요소</th>
              <th>내용</th>
              <th>점수</th>
            </tr>
            <tr>
              <th rowspan="3">업무열정</th>
              <td>
                평소 일을 더 잘하고, 상황을 개선하기 위한 방법을 우선적으로
                찾는다.
              </td>
              <td class="txt-center">{{ pop.mcr.competency.energetic[0] }}</td>
            </tr>
            <tr>
              <td>
                직무에 대한 열정과 열심히, 활기차게 일하려는 의지를 꾸준하게
                보여준다.
              </td>
              <td class="txt-center">{{ pop.mcr.competency.energetic[1] }}</td>
            </tr>
            <tr>
              <td>
                내가 요구하는 것을 뛰어넘고, 지시를 내리기 전에 행동하려는
                의지를 보여준다.
              </td>
              <td class="txt-center">{{ pop.mcr.competency.energetic[2] }}</td>
            </tr>
            <tr>
              <th rowspan="3">업무내용</th>
              <td>
                전체와 부분, 인과관계를 파악할 줄 알고, 우선 순위 설정,
                스케쥴링에 능숙하다.
              </td>
              <td class="txt-center">{{ pop.mcr.competency.management[0] }}</td>
            </tr>
            <tr>
              <td>항상 제시간 안에 결과물을 제출한다.</td>
              <td class="txt-center">{{ pop.mcr.competency.management[1] }}</td>
            </tr>
            <tr>
              <td>
                업무 결과물의 퀄리티 부분에서 절대 나를 실망시키지 않는다.
              </td>
              <td class="txt-center">{{ pop.mcr.competency.management[2] }}</td>
            </tr>
            <tr>
              <th rowspan="3">업무태도</th>
              <td>업무 습관(지각, 휴식 시간 등)이 모범적이다.</td>
              <td class="txt-center">{{ pop.mcr.competency.attitude[0] }}</td>
            </tr>
            <tr>
              <td>
                내가 자리에 있고 없는 것과 상관없이, 꾸준히 성실하게 일할
                것이다.
              </td>
              <td class="txt-center">{{ pop.mcr.competency.attitude[1] }}</td>
            </tr>
            <tr>
              <td>업무 진행 현황을 지속적으로 확인할 필요가 없다.</td>
              <td class="txt-center">{{ pop.mcr.competency.attitude[2] }}</td>
            </tr>
            <tr>
              <th>동료관계</th>
              <td>주변 동료들과 잘 협력한다.</td>
              <td class="txt-center">{{ pop.mcr.competency.communion[0] }}</td>
            </tr>

            <tr v-if="pop.info.idx_position == 6201">
              <th rowspan="7">역량<br />리뷰</th>
              <th>요소</th>
              <th>내용</th>
              <th>점수</th>
            </tr>
            <tr v-if="pop.info.idx_position == 6201">
              <th rowspan="6">리더십</th>
              <td>
                팀원이 성과를 개선하는 데 도움이 되는 실행 가능한 피드백을
                제공한다.
              </td>
              <td class="txt-center">{{ pop.mcr.competency.leadership[0] }}</td>
            </tr>
            <tr v-if="pop.info.idx_position == 6201">
              <td>
                리드가 개입하지 않아도 되는 문제에 시시콜콜 간섭하지 않고,
                필요한 상황에만 적절하게 개입한다.
              </td>
              <td class="txt-center">{{ pop.mcr.competency.leadership[1] }}</td>
            </tr>
            <tr v-if="pop.info.idx_position == 6201">
              <td>팀원들이 팀에 우선적으로 중요한 일에 집중하도록 한다.</td>
              <td class="txt-center">{{ pop.mcr.competency.leadership[2] }}</td>
            </tr>
            <tr v-if="pop.info.idx_position == 6201">
              <td>팀원들을 하나의 인격체로 대하며 배려한다.</td>
              <td class="txt-center">{{ pop.mcr.competency.leadership[3] }}</td>
            </tr>
            <tr v-if="pop.info.idx_position == 6201">
              <td>팀원들과 소통하면서 팀의 목표를 정확하게 제시한다.</td>
              <td class="txt-center">{{ pop.mcr.competency.leadership[4] }}</td>
            </tr>
            <tr v-if="pop.info.idx_position == 6201">
              <td>팀원들의 문제를 해결하기 위해 효과적인 방법을 제공한다.</td>
              <td class="txt-center">{{ pop.mcr.competency.leadership[5] }}</td>
            </tr>
            <tr>
              <th colspan="2">월별 평균 점수</th>
              <td>{{ pop.mcr.avg_comment}}</td>
              <td class="txt-center">{{ pop.mcr.avg_mcr }}</td>
            </tr>
            <!--
            <tr>
              <th colspan="2">월별 합계 점수</th>
              <td>{{ pop.mcr.total_comment }}</td>
              <td class="txt-center">{{ pop.mcr.total_mcr }}</td>
            </tr>
            -->

          </tbody>
        </table>
        <table class="tbl-light-view mt-10">
          <colgroup>
            <col width="300" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>종합 Comment</th>
              <td class="preline">{{ pop.mcr.comment }}</td>
            </tr>
            <tr>
              <th>Guide & Check Point</th>
              <td class="preline">{{ pop.mcr.guidencheck }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, reactive } from "vue";
import axios from "@/plugins/axios.js";

export default {
  name: "MCRViewPopup",
  props: {
    idx: {
      type: Number,
      default: 0,
    },
    idx_hq: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const pop = reactive({
      idx: 0,
      idx_hq: 0,
      info: {},
      mcr: {
        competency: {
          energetic: [],
          management: [],
          attitude: [],
          communion: [],
          leadership: [],
        },
        comment: "",
        guidencheck: "",
        avg_comment:"",
        avg_mcr:"",
      },

      doSearch: () => {
        let params = {
          idx: pop.idx,
          idx_hq: pop.idx_hq,
        };

        axios.get("/rest/mypims/getMCRInfo", { params: params }).then((res) => {
          if (res.data.err == 0) {
            pop.info = res.data.info;

            pop.mcr = res.data;
          } else {
            console.log(res.data);
          }
        });
      },

      onClose: () => {
        emit("close");
      },
    });

    onMounted(() => {
      // Mounted
      pop.idx = props.idx ? props.idx : "0";
      pop.idx_hq = props.idx_hq ? props.idx_hq : "0";

      pop.doSearch();
    });

    onUnmounted(() => {
      // UnMounted
    });

    return { pop };
  },
};
</script>
<style lang="scss" scoped></style>
