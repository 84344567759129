<template>
    <div class="con-wrap">
        <MCRViewPopup :idx="mcr.idx" :idx_hq="mcr.idx_hq" v-if="mcr.isShow" @close="mcr.isShow=false"></MCRViewPopup>
        <MCGViewPopup :idx="mcg.idx" :idx_hq="mcg.idx_hq" v-if="mcg.isShow" @close="mcg.isShow=false"></MCGViewPopup>
        <CarrotTitle :title="view.info.ename+'('+view.info.kname+') MCR/MCG 상세'"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-col">
                        <colgroup>
                           <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="*">
                            <col width="*">
                            <col width="110">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>부문</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>팀원</th>
                                <th>직급</th>
                                <th>입사일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ view.info.sector_name }}</td>
                                <td>{{ view.info.office_name }}</td>
                                <td class="txt-ellipsis">{{ view.info.team_name }}</td>
                                <td>{{ view.info.ename }}({{ view.info.kname }})</td>
                                <td>{{ view.info.position_name }}</td>
                                <td>{{ view.info.joindate }}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="mt-50 mb-20">
                        <select name="syear" id="syear" class="w-200px" v-model="view.syear" @change="view.doSearch">
                            <option :value="v" v-for="(v, k) in view.search_years" :key="k">{{ v }}</option>
                        </select>
                        <div class="clear"></div>
                    </div>

                    <div class="dp-table w-100per mt-50 ">
                        <div v-if="view.info.idx_position=='6104'" class="dp-inblock w-20per pr-25">
                            <div class="block-title mb-20">리드 MCR 평균점수</div>
                            <table class="table-col">
                                <colgroup>
                                    <col>
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>리더십</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ view.mcr.avg_leadership }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div v-if="view.mcr.hasMCR==true" class="dp-inblock w-80per">
                            <div class="block-title mb-20">MCR 평균점수</div>
                            <table class="table-col">
                                <colgroup>
                                    <col span="5">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>전체 평균</th>
                                        <th>업무열정</th>
                                        <th>업무내용</th>
                                        <th>업무태도</th>
                                        <th>동료관계</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ view.mcr.avg_total }}</td>
                                        <td>{{ view.mcr.avg_energetic }}</td>
                                        <td>{{ view.mcr.avg_management }}</td>
                                        <td>{{ view.mcr.avg_attitude }}</td>
                                        <td>{{ view.mcr.avg_communion }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="mt-50 mb-20">
                        <div class="block-title float-left mt-10">MCR 상세내용</div>
                        <button class="btn-default float-right" @click="mcr.addMCR">MCR 등록</button>
                        <div class="clear"></div>
                    </div>
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="100">
                            <col width="100">
                            <col width="180">
                            <col width="100">
                            <col width="100">
                            <col width="100">
                            <col width="100">
                            <col width="100">
                            <col width="110">
                            <col width="130">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>평가연도</th>
                                <th>평가월</th>
                                <th>구분</th>
                                <th>업무열정</th>
                                <th>업무내용</th>
                                <th>업무태도</th>
                                <th>동료관계</th>
                                <th>리더십</th>
                                <th>작성일</th>
                                <th>상세</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.mcr.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.wyear }}</td>
                                <td>{{ irow.wmonth }}</td>
                                <td>{{ irow.reviewer_position }}</td>
                                <td>{{ irow.avg_energetic?irow.avg_energetic:'-' }}</td>
                                <td>{{ irow.avg_management?irow.avg_management:'-' }}</td>
                                <td>{{ irow.avg_attitude?irow.avg_attitude:'-' }}</td>
                                <td>{{ irow.avg_communion?irow.avg_communion:'-' }}</td>
                                <td>{{ irow.avg_leadership?irow.avg_leadership:'-' }}</td>
                                <td>{{ irow.regdate }}</td>
                                <td>
                                    <button v-if="irow.state=='Y'" class="btn-default dp-inblock" @click="mcr.showMCR(irow.idx)">보기</button>
                                    <button v-else class="btn-default dp-inblock" @click="mcr.modMCR(irow.idx)">임시저장</button>
                                </td>
                            </tr>
                            <tr v-if="view.mcr.total==0">
                                <td colspan="11">등록된 MCR 평가가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <div class="block-title float-left mt-10">MCG 상세내용</div>
                        <button class="btn-default float-right" @click="mcg.addMCG">MCG 등록</button>
                        <div class="clear"></div>
                    </div>
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="210">
                            <col width="210">
                            <col width="290">
                            <col width="210">
                            <col width="200">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>NO</th>
                                <th>등록연도</th>
                                <th>등록월</th>
                                <th>구분</th>
                                <th>작성일</th>
                                <th>상세</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.mcg.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.wyear }}</td>
                                <td>{{ irow.wmonth }}</td>
                                <td>{{ irow.reviewer_position }}</td>
                                <td>{{ irow.regdate }}</td>
                                <td>
                                    <button v-if="irow.state=='Y'" class="btn-default dp-inblock" @click="mcg.showMCG(irow.idx)">보기</button>
                                    <button v-else class="btn-default dp-inblock" @click="mcg.modMCG(irow.idx)">임시저장</button>
                                </td>
                            </tr>
                            <tr v-if="view.mcg.total==0">
                                <td colspan="6">등록된 MCG 평가가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import MCRViewPopup from '@/components/popup/myPIMS/MCRViewPopup.vue'
import MCGViewPopup from '@/components/popup/myPIMS/MCGViewPopup.vue'
import axios from '@/plugins/axios.js'
import {useStore} from "vuex";
import { useToast } from 'vue-toastification';


export default {
    layout:"myPIMS",
    components: {
        MCRViewPopup, MCGViewPopup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const store = useStore();
        const toast = useToast();

        const mcr = reactive({
            idx : 0,
            idx_hq : 0,
            // Popup 노출 여부
            isShow : false,
            is_auth: false,

            addMCR : () => {
                sessionStorage.setItem('MCRMCGView', JSON.stringify({ idx:view.idx, syear:view.syear }));
                router.push({
                    name : 'myPIMS-MCRAdd-idx',
                    params : { idx : 0, idx_hq : view.idx }
                })
            },

            modMCR : (idx) => {
                sessionStorage.setItem('MCRMCGView', JSON.stringify({ idx:view.idx, syear:view.syear }));
                router.push({
                    name : 'myPIMS-MCRAdd-idx',
                    params : { idx : idx, idx_hq : view.idx }
                })
            },

            showMCR : (idx) => {
                mcr.idx = idx;
                mcr.idx_hq = view.idx,
                mcr.isShow = true;
            },

            hideMCR : () => {
                mcr.idx = 0;
                mcr.isShow = false;
            },
        });

        const mcg = reactive({
            idx : 0,
            idx_hq : 0,
            // Popup 노출 여부
            isShow : false,

            addMCG : () => {
                sessionStorage.setItem('MCRMCGView', JSON.stringify({ idx:view.idx, syear:view.syear }));
                router.push({
                    name : 'myPIMS-MCGAdd-idx',
                    params : { idx : 0, idx_hq : view.idx }
                })
            },

            modMCG : (idx) => {
                sessionStorage.setItem('MCRMCGView', JSON.stringify({ idx:view.idx, syear:view.syear }));
                router.push({
                    name : 'myPIMS-MCGAdd-idx',
                    params : { idx : idx, idx_hq : view.idx }
                })
            },

            showMCG : (idx) => {
                mcg.idx = idx;
                mcg.idx_hq = view.idx,
                mcg.isShow = true;
            },

            hideMCG : () => {
                mcg.idx = 0;
                mcg.isShow = false;
            },
        });

        const view = reactive({
            search_years : [],

            idx   : 0,
            syear : "",
            info  : {},
            mcr   : { list:[], total:0, hasMCR:false },
            mcg   : { list:[], total:0 },

            doSearch : () => {
                let params = {
                    idx_hq : view.idx,
                    syear  : view.syear,
                  user_position: store.state.idx_position,
                  user_sector: store.state.idx_sector,
                  user_office: store.state.idx_office,
                  user_team: store.state.idx_team
                };
                axios.get("/rest/mypims/getHQEvInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data.info;

                        view.mcr  = res.data.mcr;
                        view.mcr.hasMCR = false;


                        if(store.state.idx_staff==res.data.review.review1_idx)//권한O
                          view.is_auth=true;
                        if(store.state.idx_staff==res.data.review.review2_idx)//권한O
                          view.is_auth=true;

                      //열람권한 체크 추가
                      view.is_auth=false;
                      if(store.state.pd_auth || store.state.isMaster || (view.info.auth.dept && view.info.auth.position)) //마스터권한 또는 (부문/본부/팀+직급 체크) + 피플팀
                        view.is_auth=true;
                      if(!view.is_auth){
                        toast.error("접근 권한이 없습니다.");
                        router.push({
                          path:"/myPIMS/TeamEvaluationManagementList"
                        });
                        return;
                      }
                        if( view.mcr.has_mcr == 'Y' ){
                            view.mcr.hasMCR = true;
                        }
                        view.mcg  = res.data.mcg;

                        view.makeSearchYears();
                    } else {
                        console.info(res);
                    }
                });
            },

            goList : () => {
                if( view.info.state == "F" ){
                    router.push({
                        name : "myPIMS-TeamEvaluationManagementList",
                        params : route.params
                    });
                } else if( confirm("입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?") == true ){
                    router.push({
                        name : "myPIMS-TeamEvaluationManagementList",
                        params : route.params
                    });
                }
            },
            
            makeSearchYears: () => {
                let ey = view.info.joindate.substr(0, 4);
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=ey; i--){
                    view.search_years.push(i);
                }
            }
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx;
            if ( !view.idx ) {
                router.back(-1);
            }
            view.syear = route.params.syear;
            if( !view.syear ) {
                let cd = new Date();
                view.syear = cd.getFullYear();
            }

            let ss = sessionStorage.getItem("MCRMCGView");
            if( ss ){
                ss = JSON.parse(ss);
                view.idx   = ss.idx;
                view.syear = ss.syear;
                router.replace(view.idx);
            }

            view.doSearch();



            sessionStorage.removeItem("MCRMCGView");
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {mcr, mcg, view};
    }
}
</script>

<style lang="scss" scoped>
</style>