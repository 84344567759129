<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-900px">
            <div>
                <p class="title">MCG 상세</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-600px">
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="150">
                        <col width="150">
                        <col width="150">
                        <col width="*">
                        <col width="110">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>본부</th>
                            <th>팀</th>
                            <th>팀원</th>
                            <th>직급</th>
                            <th>입사일</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ pop.info.office_name }}</td>
                            <td>{{ pop.info.team_name }}</td>
                            <td>{{ pop.info.ename }}({{ pop.info.kname }})</td>
                            <td>{{ pop.info.position_name }}</td>
                            <td>{{ pop.info.joindate }}</td>
                        </tr>
                    </tbody>
                </table>
               
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col width="150">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>주요 업무 목표</th>
                            <td class="preline">{{ pop.biz_goal }}</td>
                        </tr>
                        <tr>
                            <th>실행 사항 점검</th>
                            <td class="preline">{{ pop.pastplan }}</td>
                        </tr>
                        <tr>
                            <th>주요내용</th>
                            <td class="preline">{{ pop.mainplan }}</td>
                        </tr>
                        <tr>
                            <th>Action Plan</th>
                            <td class="preline">{{ pop.actionplan }}</td>
                        </tr>
                        <tr>
                            <th>기타 고려사항</th>
                            <td class="preline">{{ pop.etc_memo }}</td>
                        </tr>
                    </tbody>
                </table>   
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted, reactive } from 'vue'
import axios from '@/plugins/axios.js'

export default {
    name: 'MCRViewPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        },
        idx_hq: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            idx : 0,
            idx_hq : 0,
            info : {},
            biz_goal   : "",
            pastplan   : "",
            mainplan   : "",
            actionplan : "",
            etc_memo   : "",

            doSearch : () => {
                let params = {
                    idx : pop.idx,
                    idx_hq : pop.idx_hq,
                };

                axios.get('/rest/mypims/getMCGInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data.info;

                        pop.biz_goal   = res.data.biz_goal;
                        pop.pastplan   = res.data.pastplan;
                        pop.mainplan   = res.data.mainplan;
                        pop.actionplan = res.data.actionplan;
                        pop.etc_memo   = res.data.etc_memo;

                    } else {
                        console.log(res.data);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx    = props.idx?props.idx:"0";
            pop.idx_hq = props.idx_hq?props.idx_hq:"0";

            pop.doSearch();
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>